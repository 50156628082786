import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Screen from "../images/screen.png";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CTA } from "../components/cta";
import Picto_bat_new from "../images/Picto_bat_new.jpg";
import Picto_bat_old from "../images/Picto_bat_old.jpg";
import PictoDate from "../images/Pictos-date.png";
import Img261554017 from "../images/261554017.jpeg";
import Building_4 from "../images/building_4.svg";
import Building_5 from "../images/building_5.svg";
import AgridScreen from "../images/agrid.jpeg";
import Picto_feuille_1 from "../images/Picto_feuille_1.png";
import Picto_feuille_2 from "../images/Picto_feuille_2.png";
import Picto_feuille_3 from "../images/Picto_feuille_3.png";
import Picto_piece_1 from "../images/Picto_piece_1.png";
import Picto_piece_2 from "../images/Picto_piece_2.png";
import Picto_piece_3 from "../images/Picto_piece_3.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EventIcon from "@mui/icons-material/Event";
import {
  blueDeep,
  blueLink,
  blueMedium,
  greenDeep,
  greenLight,
  greenMedium,
  orange,
} from "../utils/colors";
import { deepOrange } from "@mui/material/colors";

const styles = {
  p: {
    color: blueMedium,
    fontSize: "19px",
    fontWeight: 300,
    lineHeight: "25px",
  },
  title: {
    color: blueDeep,
    fontSize: 32,
    fontWeight: 700,
    marginTop: 25,
  },
  subtitle: {
    color: blueDeep,
    fontSize: 24,
    fontWeight: "bold",
  },
  card: {
    // height: "100%",
  },
  check: {},
};

const steps = [
  {
    label: "Améliorez",
    description: `Améliorez votre score énergétique via des travaux d’isolation, de menuiserie, etc...`,
  },
  {
    label: "Installez",
    description:
      "Installez des équipements performants et des dispositifs de contrôle et de gestion active de ces équipements",
  },
  {
    label: "Optimisez",
    description: `Optimisez l’exploitation de ces équipements`,
  },
  {
    label: "Encouragez",
    description: `Encouragez vos occupants à adopter un comportement écoresponsable.`,
  },
];

const LabelIcon = (props) => {
  const [color, setColor] = React.useState("rgba(12, 68, 111, 1)");
  React.useEffect(() => {
    let newColor = `rgba(12, 68, 111, ${0.4 + props.icon * 0.1})`;
    setColor(newColor);
  }, []);
  return (
    <div
      style={{
        width: 50,
        height: 50,
        borderRadius: "50%",
        background: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        marginLeft: -12,
      }}
    >
      {props?.icon}
    </div>
  );
};

function Main(props) {
  const { posts, title } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [selected, setSelected] = React.useState(null);
  const [faqIndex, setFaqIndex] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <br />
      <Typography variant="h4" gutterBottom style={{ ...styles.title }}>
        Qu’est-ce que le décret BACS ?
      </Typography>
      <Divider style={{ color: blueMedium }} />
      <Typography style={{ ...styles.p, marginTop: 25 }}>
        Paru le 20 juillet 2020, le{" "}
        <b>
          {" "}
          <a
            href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000042128488/"
            target="_blank"
            style={{ color: blueLink, textDecoration: "none" }}
          >
            décret BACS
          </a>
        </b>{" "}
        - Building Automation & Controls System - s’inscrit dans la continuité
        du{" "}
        <a
          href="https://decret-tertiaire.a-grid.com/"
          target="_blank"
          style={{ color: blueLink, textDecoration: "none" }}
        >
          <b> décret Tertiaire</b>
        </a>{" "}
        et la volonté des pouvoirs publics de pousser les acteurs du secteur
        tertiaire non-résidentiels à réduire leur consommation énergétique.
      </Typography>
      <br />
      <Typography style={{ ...styles.p }}>
        Les bâtiments neufs pour lesquels un permis de construire doit être
        déposes et entrant dans le champ d’application du décret doivent d’ores
        et déjà être équipés de systèmes d’automatisation et de contrôle. Les
        autres bâtiments concernés par l’obligation devront être équipés au plus
        tard le 1er janvier 2025.
        <br />
        <br />
        Les bâtiments tertiaires non résidentiels devront ainsi être équipés 
        d’un système maitrisant la consommation énergétique. L’application de ce
        décret constitue un nouveau moyen d’atteindre les objectifs mis en avant
        dans le cadre de la loi ELAN et du décret tertiaire.
      </Typography>
      <br />
      <br />
      <Typography
        variant="h4"
        gutterBottom
        style={{ ...styles.title }}
        id="objectifs"
      >
        Quels sont les objectifs du décret BACS ?
      </Typography>
      <Divider />
      <br />
      <Typography variant="h4" gutterBottom style={{ ...styles.p }}>
        Les objectifs du décret BACS sont les suivants : <br /> <br />
        <li>
          <b>Suivre, enregistrer et analyser</b> les données de production et de
          consommation énergétique.
        </li>{" "}
        <br />
        <li>
          <b>Ajuster</b> les systèmes techniques en fonction des besoins.
        </li>{" "}
        <br />
        <li>
          <b>Évaluer l’efficacité énergétique, détecter et informer</b>{" "}
          l’exploitant de possibilité d’amélioration de l’efficacité
          énergétique.
        </li>
      </Typography>
      {/* <br />
      <Typography variant="h4" gutterBottom style={{ ...styles.title }}>
        Quels sont les objectifs ?
      </Typography>
      <Divider />
       */}
      <br />
      <br />
      <Typography
        variant="h4"
        gutterBottom
        style={{ ...styles.title }}
        id="qui"
      >
        Qui est concerné ?
      </Typography>
      <Divider />
      <br />
      <div style={{ width: "100%" }}>
        <Typography style={{ ...styles.p }}>
          Le décret BACS concerne <b>l’intégralité des bâtiments</b>, neufs et
          existants, dont la somme de la <b>puissance nominale</b> de son
          système de CVC (Chauffage, Ventillation et Climatisation) est{" "}
          <b>supérieure à 290 kW.</b>
        </Typography>
        <br />
      </div>
      <Typography variant="h4" gutterBottom style={{ ...styles.subtitle }}>
        >> 2 cas de figure :
      </Typography>
      <br />
      <br />
      <Grid container spacing={5} alignItems="center" justifyContent={"center"}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              height: 200,
            }}
          >
            <img
              src={Picto_bat_old}
              style={{ objectFit: "contain", marginBottom: 10, width: 200 }}
            />
          </div>
          <div style={{ ...styles.p, textAlign: "center", marginTop: 10 }}>
            Votre bâtiment a été construit <br />
            <b>avant 2021</b> <br />
            => Vous avez jusqu’au <b>1er janvier 2025</b> pour répondre à cette
            obligation
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              height: 200,
            }}
          >
            <img
              src={Picto_bat_new}
              style={{ objectFit: "contain", marginBottom: 10, width: 200 }}
            />
          </div>
          <div style={{ ...styles.p, textAlign: "center", marginTop: 10 }}>
            Votre permis de construire a été déposé{" "}
            <b>après le 21 juillet 2021</b>
            <br /> => Vous devez répondre à cette obligation{" "}
            <b>dès la construction </b>
            de votre bâtiment.
          </div>
        </Grid>
      </Grid>{" "}
      <br />
      <br />
      <Typography variant="h4" gutterBottom style={{ ...styles.subtitle }}>
        A noter
      </Typography>
      <Typography style={{ ...styles.p }}>
        Le décret prévoit des exemptions. Lorsqu’il est impossible techniquement
        et économiquement d’installer un système d’automatisation et de contrôle
        des bâtiments ; le propriétaire devra réaliser une étude démontrant que
        l’installation n’est pas réalisable avec un retour sur investissement
        inférieur à 6 ans (déduction faite des aides financières publiques).
      </Typography>
      <br />
      <br />
      <Typography
        variant="h4"
        gutterBottom
        style={{ ...styles.title }}
        id="Echeances"
      >
        Quelle est l’échéance du décret BACS ?
      </Typography>
      <Divider />
      <br />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // flexDirection: "column",
        }}
      >
        <ScheduleIcon style={{ fontSize: 40, width: 80, color: deepOrange }} />
        <Typography style={{ ...styles.p, textAlign: "center" }}>
          Dès <b>aujourd’hui</b> pour les nouveaux bâtiments dont le permis de
          construire doit être déposé
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          // flexDirection: "column",
        }}
      >
        <EventIcon style={{ fontSize: 40, width: 80, color: deepOrange }} />
        {/* <img src={PictoDate} style={{ width: 80, objectFit: "contain" }} /> */}
        <Typography style={{ ...styles.p, textAlign: "center" }}>
          Avant <b>1er janvier 2025</b> pour tous les autres bâtiments
        </Typography>
      </div>
      <br />
      <br />
      <Typography
        variant="h4"
        gutterBottom
        style={{ ...styles.title }}
        id="agrid"
      >
        Quelle solution Agrid peut vous apporter ?
      </Typography>
      <Divider />
      <br />
      <Typography style={{ ...styles.p }}>
        Les équipements les plus énergivores dans un bâtiment sont les CVC
        (Chauffage, Ventillation et Climatisation). Ce sont donc eux que vous,
        <b> propriétaires de bâtiments tertiaires</b>, devez réguler en
        priorité.
        <br />
        <br />
        Il existe <b>3 types de régulation</b> des équipements CVC permettant de
        répondre aux exigences du décret BACS :
      </Typography>
      <br />
      <Grid container justifyContent={"center"}>
        <Grid item sx={12} md={8} style={{ overflowX: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    ...styles.p,
                    borderLeft: `1px solid rgba(224, 224, 224, 1)`,
                  }}
                >
                  <b> Gains financiers</b>
                </TableCell>
                <TableCell
                  style={{
                    ...styles.p,
                    borderLeft: `1px solid rgba(224, 224, 224, 1)`,
                  }}
                >
                  <b>Économies d’énergies</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                {
                  text: (
                    <Typography style={{ ...styles.p }}>
                      <b>Régulation programmée</b> : programmation hebdomadaire,
                      saisonnière, etc...
                    </Typography>
                  ),
                  img1: Picto_piece_1,
                  img2: Picto_feuille_1,
                },

                {
                  text: (
                    <Typography style={{ ...styles.p }}>
                      <b>Régulation réactive en temps réel</b> : adaptation à la
                      météo extérieure
                    </Typography>
                  ),
                  img1: Picto_piece_2,
                  img2: Picto_feuille_2,
                },
                {
                  text: (
                    <Typography style={{ ...styles.p }}>
                      <b> Régulation prédictive en anticipation</b>
                    </Typography>
                  ),
                  img1: Picto_piece_3,
                  img2: Picto_feuille_3,
                },
              ].map((el) => {
                return (
                  <TableRow
                    key={"row"}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: blueMedium }}
                    >
                      {el.text}
                    </TableCell>
                    <TableCell
                      style={{ borderLeft: `1px solid rgba(224, 224, 224, 1)` }}
                    >
                      {" "}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img src={el.img1} style={{ width: 60, height: 60 }} />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ borderLeft: `1px solid rgba(224, 224, 224, 1)` }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img src={el.img2} style={{ width: 60, height: 60 }} />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <br />
      <Typography style={{ ...styles.p }}>
        Pour vous accompagner dans votre démarche de transition énergétique et
        dans la réalisation de vos objectifs,{" "}
        <b>
          Agrid vous propose une solution 100% logicielle de régulation
          prédictive.
        </b>{" "}
        Notre solution optimisera vos consommations énergétiques et vous
        permettra de répondre aux exigences du décret BACS.
        <br />
        <br />
        Grâce à <b>l’intelligence artificielle</b>, l’analyse continue des
        données de votre bâtiment et notre expertise, Agrid-Link pilotera vos
        équipements CVC (Chauffage, Ventillation et Climatisation) en prenant en
        compte différents facteurs d’influence des consommations. Nous prenons
        en compte les conditions météorologiques, taux d’occupation, et beaucoup
        d’autres paramètres.
      </Typography>
      <br />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <CTA title="Découvrez notre solution !" link="https://a-grid.com" />
      </div>
      <br />
      <Grid container style={{ marginTop: 20 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography style={{ ...styles.p }}>
            Vous avez besoin de <b>plus d’informations</b> concernant la
            <b>{" solution Agrid-Link"}</b> ?<br />
            <br />
            <CheckIcon /> <b>Une installation rapide</b> en quelques minutes{" "}
            <br /> <br /> <CheckIcon /> Une solution <b>discrète</b> et efficace{" "}
            <br />
            <br />
            <CheckIcon />
            <b>Aucune</b> pose de capteurs <br />
            <br />
            <CheckIcon /> Le <b>confort</b> de vos occupants reste{" "}
            <b>inchangé</b> <br />
            <br />
            <CheckIcon /> <b>Aucun investissement</b> initial <br />
            <br />
            <CheckIcon /> <b>Jusqu'à 25% d'économie</b> d'énergie sur vos
            dépenses de chauffage / climatisation dès le premier mois
            <br />
            <br />
            <CheckIcon /> <b>Une facturation annuelle</b> sous forme
            d'abonnement. Pas de frais cachés
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <CTA
              title="Pour plus d'informations, contactez nous...  "
              link="https://a-grid.com/contact"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ position: "relative", display: "flex" }}
        >
          <img
            src={Img261554017}
            style={{ objectFit: "contain", maxWidth: "90%" }}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <Typography
        variant="h4"
        gutterBottom
        style={{ ...styles.title }}
        id="faq"
      >
        FAQ
      </Typography>
      <Divider />
      <br />
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography gutterBottom style={{ ...styles.subtitle }}>
            + Quelle différence entre le Décret Tertiaire et le Décret BACS ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ ...styles.p }}>
            Pour répondre aux défis de la transition écologique,{" "}
            <b>
              le décret Tertiaire impose une réduction progressive de la
              consommation d’énergie
            </b>{" "}
            dans les bâtiments à usage tertiaire (moins 40% en 2030, moins 50%
            en 2040 et moins 60% en 2050).
            <br />
            <br />
            Les actions à déployer vont au-delà des travaux de rénovation
            énergétique des bâtiments. Elles impliquent aussi d’optimiser
            l’exploitation des équipements et de mettre en œuvre des stratégies
            de management de l’énergie durables. C’est sur ce plan là que le
            décret BACS intervient. <br />
            <br />
            Dans le cadre de la démarche d’amélioration de la performance
            énergétique des bâtiments, le décret BACS rend obligatoire l’
            <b>
              installation de systèmes d’automatisation et de contrôle.
            </b>{" "}
            Sont concernés tous les bâtiments tertiaires neufs et existants,
            dont la puissance nominale des systèmes de chauffage ou de
            climatisation est *supérieure à 290 kW.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography gutterBottom style={{ ...styles.subtitle }}>
            + Qu’est-ce qu’un système d’automatisation et de contrôle des
            bâtiments ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ ...styles.p }}>
            Un système d’automatisation et de contrôle des bâtiments est avant
            tout un logiciel qui permet d’assurer le suivi et le pilotage des
            consommations énergétiques et équipements du bâtiment tels que la
            climatisation, le chauffage ou la ventilation.
            <br />
            <br />
            Un tel logiciel permet :
            <li>
              <b>De centraliser, enregistrer et analyser</b> les données de
              consommation énergétique du bâtiment et de l’ajuster en fonction
              des besoins des occupants, de l’activité ou encore de la météo,
            </li>
            <li>
              <b>De collaborer, de communiquer</b> avec les équipements du
              bâtiment pour les piloter à distance,
            </li>{" "}
            <li>
              De <b>situer l’efficacité énergétique</b> du bâtiment par rapport
              à des valeurs de référence. Ce système permet d’identifier les
              points d’économie d’énergie, de détecter les dysfonctionnements et
              d’alerter les personnes concernées.
            </li>
            <br />
            L’installation d’un tel système permet d’obtenir des résultats
            significatifs et des gains immédiats sur la performance énergétique
            du bâtiment avec un temps de retour sur investissement court.
            <br />
            <br /> Pour en savoir plus sur notre logiciel Agrid-Link,{" "}
            <a
              href="https://a-grid.com/contact"
              target="_blank"
              style={{
                color: orange,
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              contactez nous ici{" "}
            </a>
            !
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography gutterBottom style={{ ...styles.subtitle }}>
            + Comment connaître sa puissance nominale ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ ...styles.p }}>
            <b>Chaque système CVC</b> (Chauffage, Ventilation et Climatisation)
            dispose d’une fiche technique où est indiquée la puissance nominale
            thermique que peut fournir l’équipement dans des conditions très
            particulières (dites conditions nominales). <br /> <br />
            Il vous suffit de regrouper les fiches techniques de l’intégralité
            de vos équipement CVC et d’en additionner les caractéristiques pour
            connaître votre puissance nominale totale. <br /> <br />
            Si vous possédez un bâtiment dont{" "}
            <b>la superficie est supérieure à 5000m²</b>, vous avez fort à
            parier que votre puissance nominale est supérieure à 290 kW. Une
            dernière piste, le responsable HSE (Hygiène, Sécurité et
            Environnement) de votre bâtiment possède peut être un registre
            détaillé de vos équipements où il est inscrit votre puissance
            nominale. <br /> <br />
            <b>Cas pratique </b>: Un établissement est équipé de :
            <li> 8 moteurs DAIKIN VRV IV LOOP (Modèle:RXYQ10U7Y1B)</li>
            <li> 1 moteur DAIKIN VRV IV Inverter ( Modèle: RXYSCQ4TMV1B)</li>
            <br />
            En consultant les fiches techniques des 2 moteurs, dans la partie “spécifications techniques”, les données qui nous intéressent sont “cooling capacity” et “heating capacity”, nous constatons que les grands moteurs utilisent jusqu’à 28kW en froid et jusqu’à 16 kW en chaud. Sur la fiche technique du petit moteur, nous voyons qu’il consomme 12,1 kW en cooling et autant en heating.
            <br />
            <br /> <b>Le calcul de la puissance nominale totale</b> est simple :{" "}
            <li>Cooling capacity : (28x8) + 12,1 = 236 kW</li>{" "}
            <li>Heating capacity : (16x8) + 12,1 = 140,1 kW</li> <br />
            Cet établissement
            <b> ne dépasse pas les 290 kW</b>,{" "}
            <span style={{ textDecoration: "underline" }}>
              il n’est donc pas concerné par le décret BACS.
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default Main;
